import logoIcon from '../assets/images/walterLogoWhiteBorder.svg';
import oopsAnimation from '../assets/images/rafinhaAnimFast2.gif';
import React from 'react';
import background from '../assets/images/background/background_landscape.png';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';
import logo from '../assets/images/walterLogoWhiteBorder.svg';


export const ErrorPage = () => {
  return (
      <div className="w-full h-full overflow-auto" style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        transform: 'scale(1.1)',
        position: 'absolute',
        filter: 'blur(0px) brightness(100%)',
        backgroundRepeat: 'no-repeat'
      }}>

        {/*Top left Logo*/}
        <div className="w-full lg:w-6/12 sm:w-6/12 md:w-4/12 ml-24 mt-10 absolute oscillateVertically z-10"
             style={{ justifySelf: 'start', alignSelf: 'start' }}>
          <img className="mainLogo h-20 sm:h-20 md:h-30 lg:h-30 absolute z-10" src={logo} alt=""
               style={{ justifySelf: 'center', alignSelf: 'center' }} draggable={false} />
        </div>

      <MouseParallaxContainer
        className="w-full h-full flex justify-center items-center"
        containerStyles={{
          width: '100%',
        }}
      >
        <MouseParallaxChild
          className="h-full"
          factorX={0.01}
          factorY={0.0}
          updateStyles={{
            transform: 'scale(1.1)',
            position: 'absolute',
            filter: 'blur(0px) brightness(100%)',
            backgroundRepeat: 'no-repeat'
          }}
        >

          <div
            className="h-full w-full flex items-center justify-end flex-col">
            <div className="w-3/4 bg-black bg-opacity-80 flex justify-around py-4">
              <div className="flex flex-col p-4">
                <p className="text-bold text-white">Error</p>
                <p className="font-bold text-xl text-white">Very bad error!</p>
              </div>
            </div>

            <img className="" src={oopsAnimation} alt="" />
          </div>
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </div>
  );
};
