import React, { useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import logoIcon from '../assets/images/walter_head.png';
import mainCharacter from '../assets/images/anim/superWalter.webp';
import textBubbleSmall from '../assets/images/background/text_bubble_small.svg';
import background from '../assets/images/background/background_landscape.png';
import logo from '../assets/images/walterLogoWhiteBorder.svg';
import star from '../assets/images/star.png';
import { useTranslation } from 'react-i18next';
import { useWalletConnectClient } from '../contexts/walletConnect';
import { toast } from 'react-toastify';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';
import Markdown from 'react-markdown'

export const About = () => {
  const [clickCounter, setClickCounter] = useState(0);
  const { t } = useTranslation(['special']);

  const [rotateStar, setRotateStar] = useState(false);

  function onRotateStar() {
    setRotateStar(!rotateStar);
  }


  function goBack() {
    if (clickCounter > 0) {
      setClickCounter(clickCounter - 1);
    }
  }

  function goForward() {
    if (clickCounter === 1) {
    }
    setClickCounter(clickCounter + 1);
  }

  const startDialog =
    <div className="w-full">
      <a className="ml-8 mt-8 clickable cursor-pointer z-10" style={{ alignSelf: 'start', position: 'absolute' }}
         onClick={() => goBack()}>
        {
          clickCounter > 0 ?
            <p className="font-righteous text-center text-black text-sm">←</p> : <div />
        }
      </a>

      <div className="w-full flex flex-col items-center justify-center bigTextBubble"
           style={{
             backgroundSize: 'cover',
             alignSelf: 'center',
             backgroundRepeat: 'no-repeat',
             visibility: 'visible'
           }}>

        <div className="z-10 flex justify-center items-center mt-20 p-10">
          <a onClick={() => goForward()}>
            {
              clickCounter === 0 ?
                <p className="w-full mt-2 font-righteous text-center text-black"><Markdown>{t('aboutMessage1')}</Markdown></p>
                :
                clickCounter === 1 ?
                  <div className="w-full mt-2 pl-4"><p
                    className=" font-righteous text-center text-black"><Markdown>{t('aboutMessage2')}</Markdown></p>
                  </div>
                  :
                  <div className="w-full mt-2 pl-4"><p
                    className=" font-righteous text-center text-black"><Markdown>{t('aboutMessage2')}</Markdown></p>
                  </div>
            }
          </a>
        </div>


      </div>

    </div>;

  return (
    <div className="w-full h-full flex items-center overflow-auto">

      {/*Top left Logo*/}
      <div className="w-full lg:w-6/12 sm:w-6/12 md:w-4/12 absolute oscillateVertically z-10"
           style={{ justifySelf: 'start', alignSelf: 'start' }}>
        <img className="mainLogo h-20 sm:h-20 md:h-30 lg:h-30 absolute z-10" src={logo} alt=""
             style={{ justifySelf: 'center', alignSelf: 'center' }} draggable={false} />
      </div>

      <MouseParallaxContainer
        className="w-full h-full parallax flex justify-center items-center"
        containerStyles={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'auto auto auto auto auto'
        }}
        resetOnLeave
      >


        {/* Background image */}
        <MouseParallaxChild
          className="w-full h-full bg-red-400"
          factorX={0.01}
          factorY={0.0}
          updateStyles={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'contain',
            transform: 'scale(1.0)',
            position: 'absolute',
            filter: 'blur(0px) brightness(100%)',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden'
          }}
        >
          {/*Star on top right*/}
          <div className="w-full flex justify-end">
            <img className={`h-8 w-8 rotatingStar ${rotateStar ? 'rotateIndefinitely' : ''}`}
                 src={star} alt=""
                 style={{
                   justifySelf: 'end',
                   alignSelf: 'end'
                 }} draggable={false}
                 onClick={onRotateStar} />
          </div>
        </MouseParallaxChild>


        <MouseParallaxChild
          factorX={0.005}
          factorY={0.0}
          className="h-full w-full flex items-center justify-end"
        >
          {/*main character section*/}
          <div
            className="w-full h-full mt-40 lg:mt-60 twoColumnContainer flex items-end justify-center">
            {/*Text Bubble*/}
            <div className="z-10 "> {startDialog} </div>
            <img className="z-0 h-1/2 heightSmallerOnPortrait" src={mainCharacter} alt="" />
          </div>
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </div>
  );
};

